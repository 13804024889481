import { client } from '@/services/api-client'
import { VehicleWithdrawal } from '@/types/VehicleWithdrawal'
import { VehicleWithdrawalModel } from '@/types/VehicleWithdrawalModel'
import { gql } from 'graphql-request'
import { convertToUtc } from '@/common/convertToUtc'

export const updateVehicleWithdrawal = (
  input: VehicleWithdrawalModel
): Promise<VehicleWithdrawal[]> => {
  const inputData: VehicleWithdrawalModel = Object.assign({}, input)
  if (input.ready) {
    inputData.ready = convertToUtc(input.ready)
  }
  return client
    .request<{ vehicle_withdrawal: VehicleWithdrawal[] }>(
      gql`
        mutation updateVehicleWithdrawal(
          $input: UpdateVehicleWithdrawalInput!
        ) {
          update_vehicle_withdrawal(input: $input) {
            uuid
          }
        }
      `,
      {
        input: {
          ...inputData,
          prognosis: inputData.prognosis
            ? convertToUtc(inputData.prognosis)
            : null,
          withdrawn: convertToUtc(inputData.withdrawn),
        },
      }
    )
    .then((data) => data.vehicle_withdrawal)
}
