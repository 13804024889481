
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import AppFieldSelect from '@/components/AppFieldSelect.vue'
import AppFieldDate from '@/components/AppFieldDate.vue'
import {
  required as requiredRule,
  validDateRule,
  minCharactersRule,
} from '@/common/formValidationRules'
import { format } from 'date-fns'
import { vehicleWithdrawalFormProps } from '@/common/vehicleWithdrawalFormProps'
import { VehicleWithdrawalModel } from '@/types/VehicleWithdrawalModel'
import { VehicleWithdrawal } from '@/types/VehicleWithdrawal'
import { useProfile } from '@/services/profile'

export default defineComponent({
  name: 'VehicleWithdrawalForm',

  props: {
    ...vehicleWithdrawalFormProps,
    vehicleWithdrawals: Array as PropType<VehicleWithdrawal[]>,
    formType: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object as PropType<VehicleWithdrawalModel>,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  components: {
    AppFieldSelect,
    AppFieldDate,
  },

  setup(props, { emit }) {
    const { can } = useProfile()
    const showPrognosis = ref(!!props.modelValue.prognosis)
    if (props.formType === 'create') {
      showPrognosis.value = false
    }
    const showEventIdInput = ref(false)
    const preVehicleUuid = Object.assign({}, props.modelValue).vehicle_uuid
    const model = ref<VehicleWithdrawalModel>(
      Object.assign({}, props.modelValue)
    )
    const withdrawnDate = ref(format(model.value.withdrawn, 'yyyy-MM-dd'))
    const prognosisDate = ref(
      format(model.value.prognosis || new Date(), 'yyyy-MM-dd')
    )

    const withdrawnTime = ref(format(model.value.withdrawn, 'HH:mm'))
    const prognosisTime = ref(
      format(model.value.prognosis || new Date(), 'HH:mm')
    )

    const vehicleComposition = ref(null)
    const vehicleUuids = ref<string[]>([])

    const vehicleWithdrawalsMap = (props.vehicleWithdrawals || []).reduce<{
      [key: string]: VehicleWithdrawal
    }>((acc, vehicleWithdrawal) => {
      acc[vehicleWithdrawal.vehicle.name] = vehicleWithdrawal
      return acc
    }, {})

    const errorValidVehicles = computed(() => {
      if (
        props.formType === 'update' &&
        preVehicleUuid === model.value.vehicle_uuid
      ) {
        return []
      }
      return (
        props.formType === 'create'
          ? vehicleUuids.value
          : [model.value.vehicle_uuid]
      )
        .map((uuid) => (props.vehicles || []).find((x) => x.uuid === uuid))
        .filter((x) => x && vehicleWithdrawalsMap[x.name])
    })

    const availableVehicleCompositionsValid = computed(() => {
      const list = (props.availableVehicleCompositions || []).filter(
        (x) =>
          !x.vehicles.every((vehicle) => vehicleWithdrawalsMap[vehicle.name])
      )

      type Vehicle = { vehicles: { name: string }[] }
      const sortVehicleNumber = (a: Vehicle, b: Vehicle) =>
        parseInt(a.vehicles[0].name) - parseInt(b.vehicles[0].name)
      const filterVehicleStartNumber = (stringNumber: string) => (x: Vehicle) =>
        x.vehicles[0].name[0] === stringNumber
      return [
        ...list.filter(filterVehicleStartNumber('2')).sort(sortVehicleNumber),
        ...list.filter(filterVehicleStartNumber('8')).sort(sortVehicleNumber),
        ...list.filter(filterVehicleStartNumber('1')).sort(sortVehicleNumber),
      ]
    })

    watch(
      model,
      (value) => {
        emit('update:modelValue', {
          ...value,
        })
      },
      { deep: true }
    )

    watch(
      () => model.value.vehicle_withdrawal_reason_uuid,
      (value) => {
        if (!value || !props.reasons) {
          showEventIdInput.value = false
          model.value.event_id = null
          return
        }
        const reason = props.reasons.find((x) => x.uuid === value)

        if (reason && reason.name === 'AU') {
          showEventIdInput.value = true
        } else {
          showEventIdInput.value = false
          model.value.event_id = null
        }
      },
      { immediate: true }
    )

    watch(showEventIdInput, (v) => {
      if (!v) {
        model.value.event_id = null
      }
    })

    watch(vehicleComposition, (uuid) => {
      if (!props.availableVehicleCompositions || !uuid) return
      vehicleUuids.value =
        props.availableVehicleCompositions
          .find((x) => x.uuid === uuid)
          ?.vehicles.map((x) => x.uuid) || []
    })

    watch(withdrawnDate, () => {
      emit('update:modelValue', {
        ...props.modelValue,
        withdrawn: new Date(
          `${withdrawnDate.value} ${withdrawnTime.value}:00:00`
        ),
      })
    })

    watch(withdrawnTime, () => {
      emit('update:modelValue', {
        ...props.modelValue,
        withdrawn: new Date(
          `${withdrawnDate.value} ${withdrawnTime.value}:00:00`
        ),
      })
    })

    watch(prognosisDate, () => {
      emit('update:modelValue', {
        ...props.modelValue,
        prognosis: new Date(
          `${prognosisDate.value} ${prognosisTime.value}:00:00`
        ),
      })
    })

    watch(prognosisTime, () => {
      emit('update:modelValue', {
        ...props.modelValue,
        prognosis: new Date(
          `${prognosisDate.value} ${prognosisTime.value}:00:00`
        ),
      })
    })

    watch(showPrognosis, (value) => {
      if (value) {
        emit('update:modelValue', {
          ...props.modelValue,
          prognosis: new Date(
            `${prognosisDate.value} ${prognosisTime.value}:00:00`
          ),
        })
      } else {
        emit('update:modelValue', {
          ...props.modelValue,
          prognosis: null,
        })
      }
    })

    return {
      model,
      showPrognosis,
      showEventIdInput,
      requiredRule,
      validDateRule,
      minCharactersRule,
      withdrawnDate,
      prognosisDate,
      withdrawnTime,
      prognosisTime,
      vehicleComposition,
      vehicleUuids,
      errorValidVehicles,
      availableVehicleCompositionsValid,
      can,
    }
  },
})
