import { client } from '@/services/api-client'
import { AvailableVehicleComposition } from '@/types/AvailableVehicleComposition'
import { gql } from 'graphql-request'

export const getAvailableVehicleCompositions = (): Promise<
  AvailableVehicleComposition[]
> =>
  client
    .request<{
      available_vehicle_compositions: AvailableVehicleComposition[]
    }>(
      gql`
        query getAvailableVehicleCompositions {
          available_vehicle_compositions {
            uuid
            vehicles {
              uuid
              name
            }
          }
        }
      `
    )
    .then((data) => data.available_vehicle_compositions)
