import { client } from '@/services/api-client'
import { Vehicle } from '@/types/Vehicle'
import { gql } from 'graphql-request'

export const getVehicle = (): Promise<Vehicle[]> =>
  client
    .request<{
      masterdata: {
        vehicles: Vehicle[]
      }
    }>(
      gql`
        query getVehicle {
          masterdata {
            vehicles {
              uuid
              name
              start_date
              end_date
              vehicle_type {
                uuid
                name
              }
            }
          }
        }
      `
    )
    .then((data) => data.masterdata.vehicles)
