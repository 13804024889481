
import { defineComponent } from 'vue'
import VehicleWithdrawalTable from '@/components/vehicle-withdrawal/VehicleWithdrawalTable.vue'
import VehicleWithdrawalReportStatusTable from '@/components/vehicle-withdrawal/VehicleWithdrawalReportStatusTable.vue'
import { computed } from '@vue/reactivity'
import { useVehicle } from '@/compositions/useVehicle'
import { useVehicleWithdrawal } from '@/compositions/useVehicleWithdrawal'
import { endOfDay, startOfDay } from 'date-fns'

export default defineComponent({
  components: {
    VehicleWithdrawalTable,
    VehicleWithdrawalReportStatusTable,
  },

  name: 'VehicleWithdrawal',

  setup() {
    const { data: vehicles } = useVehicle()
    const { data: vehicleWithdrawals, showVehicleStatusMenu } =
      useVehicleWithdrawal()
    const list = computed(() => {
      const dateTime = +new Date()
      const validVehicles = vehicles.value.filter(
        (x) =>
          dateTime >= +startOfDay(new Date(x.start_date)) &&
          dateTime <= +endOfDay(new Date(x.end_date))
      )
      const total = {
        X10P: validVehicles.filter((x) => x.vehicle_type.name === 'X10P')
          .length,
        UBP: validVehicles.filter((x) => x.vehicle_type.name === 'UBP').length,
        UBXP: validVehicles.filter((x) => x.vehicle_type.name === 'UBXP')
          .length,
        X15p: validVehicles.filter((x) => x.vehicle_type.name === 'X15p')
          .length,
      }
      const withdrawn = {
        X10P: vehicleWithdrawals.value.filter(
          (x) => x.vehicle.vehicle_type.name === 'X10P'
        ).length,
        UBP: vehicleWithdrawals.value.filter(
          (x) => x.vehicle.vehicle_type.name === 'UBP'
        ).length,
        UBXP: vehicleWithdrawals.value.filter(
          (x) => x.vehicle.vehicle_type.name === 'UBXP'
        ).length,
        X15p: vehicleWithdrawals.value.filter(
          (x) => x.vehicle.vehicle_type.name === 'X15p'
        ).length,
      }

      return [
        {
          label: 'Motorvagn X10P',
          value: {
            available: total.X10P - withdrawn.X10P,
            withdrawn: withdrawn.X10P,
            total: total.X10P,
          },
        },
        {
          label: 'Mellanvagn UBP',
          value: {
            available: total.UBP - withdrawn.UBP,
            withdrawn: withdrawn.UBP,
            total: total.UBP,
          },
        },
        {
          label: 'Manövervagn UBXP',
          value: {
            available: total.UBXP - withdrawn.UBXP,
            withdrawn: withdrawn.UBXP,
            total: total.UBXP,
          },
        },
        {
          label: 'X15p',
          value: {
            available: total.X15p - withdrawn.X15p,
            withdrawn: withdrawn.X15p,
            total: total.X15p,
          },
        },
      ]
    })

    return {
      list,
      showVehicleStatusMenu,
    }
  },
})
