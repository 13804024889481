
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'VehicleWithdrawalConfirmBackInTimeModal',

  emits: ['close', 'confirm'],

  setup(props, { emit }) {
    const closeModal = () => {
      emit('close')
    }

    const confirm = () => {
      emit('confirm')
      closeModal()
    }

    return {
      closeModal,
      confirm,
    }
  },
})
