import { AvailableVehicleComposition } from '@/types/AvailableVehicleComposition'
import { Vehicle } from '@/types/Vehicle'
import { VehicleWithdrawalCause } from '@/types/VehicleWithdrawalCause'
import { VehicleWithdrawalDepot } from '@/types/VehicleWithdrawalDepot'
import { VehicleWithdrawalReason } from '@/types/VehicleWithdrawalReason'
import { VehicleWithdrawalResponsible } from '@/types/VehicleWithdrawalResponsible'
import { PropType } from 'vue'

export const vehicleWithdrawalFormProps = {
  causes: {
    type: Array as PropType<VehicleWithdrawalCause[]>,
    required: true,
  },
  reasons: {
    type: Array as PropType<VehicleWithdrawalReason[]>,
    required: true,
  },
  depots: {
    type: Array as PropType<VehicleWithdrawalDepot[]>,
    required: true,
  },
  responsibles: {
    type: Array as PropType<VehicleWithdrawalResponsible[]>,
    required: true,
  },
  vehicles: {
    type: Array as PropType<Vehicle[]>,
    required: true,
  },
  availableVehicleCompositions: {
    type: Array as PropType<AvailableVehicleComposition[]>,
    required: true,
  },
}
