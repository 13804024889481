
import QForm from 'quasar/src/components/form/QForm.js';
import { defineComponent, PropType, ref } from 'vue'
import VehicleWithdrawalForm from './VehicleWithdrawalForm.vue'
import VehicleWithdrawalConfirmBackInTimeModal from './VehicleWithdrawalConfirmBackInTimeModal.vue'
import { vehicleWithdrawalFormProps } from '@/common/vehicleWithdrawalFormProps'
import { vehicleWithdrawalModel } from '@/models/vehicleWithdrawalModel'
import { VehicleWithdrawalModel } from '@/types/VehicleWithdrawalModel'
import { createVehicleWithdrawal } from '@/api/vehicle-withdrawal/createVehicleWithdrawal'
import { v4 as uuidv4 } from 'uuid'
import { VehicleWithdrawal } from '@/types/VehicleWithdrawal'
import { sendMileage } from '@/api/integration/mileage/sendMileage'
import { useProfile } from '@/services/profile'
import addMinutes from 'date-fns/esm/addMinutes/index'

export default defineComponent({
  name: 'VehicleWithdrawalCreateModal',

  components: {
    VehicleWithdrawalForm,
    VehicleWithdrawalConfirmBackInTimeModal,
  },

  emits: ['close', 'confirm'],

  props: {
    ...vehicleWithdrawalFormProps,
    vehicleWithdrawals: Array as PropType<VehicleWithdrawal[]>,
  },

  setup(props, { emit }) {
    const { can } = useProfile()
    const formRef = ref<QForm | null>(null)
    const vehicleFormRef = ref()
    const pending = ref(false)
    const errorMessage = ref<string | null>(null)
    const showConfirmModal = ref(false)

    const model = ref<VehicleWithdrawalModel>(vehicleWithdrawalModel())

    model.value.uuid = uuidv4()

    const closeModal = () => {
      emit('close')
    }

    const onSubmit = async () => {
      const body = Object.assign({}, model.value)

      const vehicleUuids: string[] = vehicleFormRef.value?.vehicleUuids || []
      const vehicleErrors: VehicleWithdrawal[] =
        vehicleFormRef.value?.errorValidVehicles || []

      if (vehicleErrors.length) return

      if ('ready' in body) {
        delete body.ready
      }
      pending.value = true

      const promises = vehicleUuids.map((vehicle_uuid) => {
        const vehicleWithdrawalBody = Object.assign({}, body)
        vehicleWithdrawalBody.vehicle_uuid = vehicle_uuid
        vehicleWithdrawalBody.uuid = uuidv4()
        return createVehicleWithdrawal({
          ...vehicleWithdrawalBody,
          prognosis: vehicleFormRef.value.showPrognosis
            ? vehicleWithdrawalBody.prognosis
            : null,
        })
      })

      await Promise.all(promises)
        .then(() => {
          pending.value = false
          emit('confirm')
          closeModal()
        })
        .then(() => sendMileage(vehicleUuids.map((uuid) => ({ uuid }))))
        .catch((err) => {
          pending.value = false
          errorMessage.value = err.response?.errors[0]?.message || null
        })
    }

    const validateAndConfirm = async () => {
      const isValid = await formRef.value?.validate()
      errorMessage.value = null

      if (!isValid) return

      if (model.value.withdrawn < addMinutes(new Date(), -15)) {
        if (!can('vehicleWithdrawal.createBackInTime')) {
          errorMessage.value =
            'Behörighet för att skapa avställningar bakåt i tiden saknas'
          return
        }

        showConfirmModal.value = true
        return
      }

      onSubmit()
    }

    return {
      closeModal,
      onSubmit,
      formRef,
      model,
      pending,
      vehicleFormRef,
      errorMessage,
      showConfirmModal,
      validateAndConfirm,
    }
  },
})
