import { client } from '@/services/api-client'
import { VehicleWithdrawal } from '@/types/VehicleWithdrawal'
import { VehicleWithdrawalModel } from '@/types/VehicleWithdrawalModel'
import { gql } from 'graphql-request'
import { convertToUtc } from '@/common/convertToUtc'

export const createVehicleWithdrawal = (
  input: VehicleWithdrawalModel
): Promise<VehicleWithdrawal[]> =>
  client
    .request<{ vehicle_withdrawal: VehicleWithdrawal[] }>(
      gql`
        mutation createVehicleWithdrawal(
          $input: CreateVehicleWithdrawalInput!
        ) {
          create_vehicle_withdrawal(input: $input) {
            uuid
          }
        }
      `,
      {
        input: {
          ...input,
          prognosis: input.prognosis ? convertToUtc(input.prognosis) : null,
          withdrawn: convertToUtc(input.withdrawn),
        },
      }
    )
    .then((data) => data.vehicle_withdrawal)
