
import { defineComponent, PropType, ref, computed, watch } from 'vue'
import {
  required as requiredRule,
  validDateRule,
} from '@/common/formValidationRules'
import QForm from 'quasar/src/components/form/QForm.js';
import { VehicleWithdrawal } from '@/types/VehicleWithdrawal'
import { createModel } from './VehicleWithdrawalEditModal.vue'
import { format } from 'date-fns'
import { updateVehicleWithdrawal } from '@/api/vehicle-withdrawal/updateVehicleWithdrawal'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { sendMileage } from '@/api/integration/mileage/sendMileage'

export default defineComponent({
  name: 'VehicleWithdrawalConfirmReadyModal',

  components: {
    AppFieldDate,
  },

  emits: ['close', 'confirm'],

  props: {
    selectedVehicleWithdrawal: {
      type: Object as PropType<VehicleWithdrawal>,
      required: true,
    },
    vehicleWithdrawals: {
      type: Array as PropType<VehicleWithdrawal[]>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const formRef = ref<QForm | null>(null)
    const pending = ref(false)

    const readyDate = ref(format(new Date(), 'yyyy-MM-dd'))
    const readyTime = ref(format(new Date(), 'HH:mm'))
    const workorder = ref('')
    const readyComment = ref('')
    const confirmCheckout = ref(false)
    const selectedVehicleWithdrawlUuids = ref<string[]>([
      props.selectedVehicleWithdrawal.uuid,
    ])

    const isReasonAU = computed(() => {
      return (
        props.selectedVehicleWithdrawal.vehicle_withdrawal_reason_uuid ===
        '33656060-21c8-45f8-8025-7c2e6774704c'
      )
    })

    watch(
      () => confirmCheckout.value,
      () => {
        if (!formRef.value) return
        formRef.value.reset()
      }
    )

    const closeModal = () => {
      emit('close')
    }
    const vehicleWithdrawalsMap = props.vehicleWithdrawals.reduce<{
      [key: string]: VehicleWithdrawal
    }>((acc, vehicleWithdrawal) => {
      acc[vehicleWithdrawal.uuid] = vehicleWithdrawal
      return acc
    }, {})
    const vehicleWithdrawalsSameComposition = props.vehicleWithdrawals.filter(
      (x) =>
        x.vehicle_compositions.some((vc) =>
          props.selectedVehicleWithdrawal.vehicle_compositions.some(
            (x) => x.uuid === vc.uuid
          )
        )
    )

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return
      pending.value = true

      const promises = selectedVehicleWithdrawlUuids.value.map((uuid) => {
        return updateVehicleWithdrawal({
          ...createModel(vehicleWithdrawalsMap[uuid]),
          wo: workorder.value,
          ready: new Date(`${readyDate.value} ${readyTime.value}`),
          checkout_confirmed: confirmCheckout.value,
          ready_comment: readyComment.value,
        })
      })

      await Promise.all(promises)

      await sendMileage(
        selectedVehicleWithdrawlUuids.value.map((uuid) => ({
          uuid: vehicleWithdrawalsMap[uuid].vehicle.uuid,
        }))
      )

      pending.value = false
      emit('confirm')
      closeModal()
    }

    const formatDate = (date: string) => {
      return format(new Date(date), 'yyyy-MM-dd HH:mm')
    }

    return {
      closeModal,
      requiredRule,
      validDateRule,
      formRef,
      onSubmit,
      readyDate,
      readyTime,
      pending,
      selectedVehicleWithdrawlUuids,
      vehicleWithdrawalsSameComposition,
      formatDate,
      workorder,
      isReasonAU,
      confirmCheckout,
      readyComment,
    }
  },
})
