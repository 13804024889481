
import { useVehicle } from '@/compositions/useVehicle'
import { useVehicleWithdrawal } from '@/compositions/useVehicleWithdrawal'
import { endOfDay, startOfDay } from 'date-fns'
import QTable from 'quasar/src/components/table/QTable.js';
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'VehicleWithdrawalReportStatusTable',

  setup() {
    const { data: vehicles } = useVehicle()
    const { data: vehiclesWithdrawals } = useVehicleWithdrawal()
    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'X10P',
        label: 'X10P',
        field: 'X10P',
        sortable: true,
      },
      {
        align: 'left',
        name: 'UBP',
        label: 'UBP',
        field: 'UBP',
        sortable: true,
      },
      {
        align: 'left',
        name: 'UBXP',
        label: 'UBXP',
        field: 'UBXP',
        sortable: true,
      },
      {
        align: 'left',
        name: 'X15p',
        label: 'X15p',
        field: 'X15p',
        sortable: true,
      },
    ]

    const data = computed(() => {
      const dateTime = +new Date()
      const validVehicles = vehicles.value.filter(
        (x) =>
          dateTime >= +startOfDay(new Date(x.start_date)) &&
          dateTime <= +endOfDay(new Date(x.end_date))
      )
      return validVehicles.map((x) => ({
        vehicle_name: x.name,
        vehicle_type_name: x.vehicle_type.name,
        in_traffic: true,
      }))
    })

    const rows = computed(() => {
      const types = data.value.reduce<{
        X10P: { vehicle_name: string; in_traffic: boolean }[]
        UBXP: { vehicle_name: string; in_traffic: boolean }[]
        UBP: { vehicle_name: string; in_traffic: boolean }[]
        X15p: { vehicle_name: string; in_traffic: boolean }[]
      }>(
        (acc, item) => {
          const typeName = item.vehicle_type_name as
            | 'X10P'
            | 'UBXP'
            | 'UBP'
            | 'X15p'
          acc[typeName].push({
            vehicle_name: item.vehicle_name,
            in_traffic: !vehiclesWithdrawals.value.some(
              (x) => x.vehicle.name === item.vehicle_name
            ),
          })

          return acc
        },
        { X10P: [], UBXP: [], UBP: [], X15p: [] }
      )

      const length = Math.max(
        types.UBP.length,
        types.UBXP.length,
        types.X10P.length,
        types.X15p.length
      )
      return new Array(length).fill(null).map((x, i) => {
        return {
          X10P: types.X10P[i],
          UBP: types.UBP[i],
          UBXP: types.UBXP[i],
          X15p: types.X15p[i],
        }
      })
    })

    return {
      columns,
      rows,
    }
  },
})
