
import { defineComponent, ref, PropType, computed } from 'vue'

export default defineComponent({
  name: 'AppFieldSelect',

  emits: ['update:modelValue'],

  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    hideSelect: {
      type: Boolean,
      default: true,
    },
    multiple: Boolean,
    disable: Boolean,
    loading: Boolean,
    label: String,
    readonly: Boolean,
    modelValue: null,
    optionLabel: [String, Function],
    options: {
      type: Array as PropType<Array<string | { [p: string]: string }>>,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    })

    const filteredOptions = ref(props.options)

    const filterFn = (val: string, update: (Fn: () => void) => void) => {
      const needle = val.toLowerCase()

      update(() => {
        if (!needle) {
          filteredOptions.value = props.options.slice()
        }
        filteredOptions.value = props.options.slice().filter((v) => {
          let label = ''
          if (props.optionLabel && typeof v !== 'string') {
            if (typeof props.optionLabel !== 'string') {
              label = props.optionLabel(v)
            } else {
              label = v[props.optionLabel]
            }
          } else if (!props.optionLabel && typeof v === 'string') {
            label = v
          }

          return label.toLowerCase().indexOf(needle) > -1
        })
      })
    }

    return {
      filterFn,
      value,
      filteredOptions,
    }
  },
})
