import { getVehicle } from '@/api/vehicles/getVehicle'
import { Vehicle } from '@/types/Vehicle'
import { Ref, ref } from 'vue'

interface UseVehicle {
  fetchVehicles: () => Promise<Vehicle[]>
  data: Ref<Vehicle[]>
  loading: Ref<{ get: boolean }>
}

const data = ref<Vehicle[]>([])
const loading = ref({
  get: false,
})

export const useVehicle = (): UseVehicle => {
  const fetchVehicles = async () => {
    loading.value.get = true
    data.value = await getVehicle()
    loading.value.get = false
    return data.value
  }

  return {
    loading,
    data,
    fetchVehicles,
  }
}
