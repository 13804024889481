import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VehicleWithdrawalConfirmReadyModal = _resolveComponent("VehicleWithdrawalConfirmReadyModal")!
  const _component_VehicleWithdrawalLogModal = _resolveComponent("VehicleWithdrawalLogModal")!
  const _component_VehicleWithdrawalCreateModal = _resolveComponent("VehicleWithdrawalCreateModal")!
  const _component_VehicleWithdrawalEditModal = _resolveComponent("VehicleWithdrawalEditModal")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_AppFullViewTable = _resolveComponent("AppFullViewTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showConfirmReadyModal)
      ? (_openBlock(), _createBlock(_component_VehicleWithdrawalConfirmReadyModal, {
          key: 0,
          "selected-vehicle-withdrawal": _ctx.selectedVehicleWithdrawal,
          "vehicle-withdrawals": _ctx.rows,
          onClose: _cache[0] || (_cache[0] = () => (_ctx.showConfirmReadyModal = false)),
          onConfirm: _ctx.fetchVehicleWithdrawal
        }, null, 8, ["selected-vehicle-withdrawal", "vehicle-withdrawals", "onConfirm"]))
      : _createCommentVNode("", true),
    (_ctx.showLogModal)
      ? (_openBlock(), _createBlock(_component_VehicleWithdrawalLogModal, {
          key: 1,
          onClose: _cache[1] || (_cache[1] = () => (_ctx.showLogModal = false)),
          "selected-vehicle-withdrawal": _ctx.selectedVehicleWithdrawal,
          causes: _ctx.vehicleWithdrawalCause,
          reasons: _ctx.vehicleWithdrawalReason,
          depots: _ctx.vehicleWithdrawalDepot,
          responsibles: _ctx.vehicleWithdrawalResponsible,
          vehicles: _ctx.vehicles
        }, null, 8, ["selected-vehicle-withdrawal", "causes", "reasons", "depots", "responsibles", "vehicles"]))
      : _createCommentVNode("", true),
    (_ctx.showCreateModal)
      ? (_openBlock(), _createBlock(_component_VehicleWithdrawalCreateModal, {
          key: 2,
          causes: _ctx.vehicleWithdrawalCause,
          reasons: _ctx.vehicleWithdrawalReason,
          depots: _ctx.vehicleWithdrawalDepot,
          responsibles: _ctx.vehicleWithdrawalResponsible,
          vehicles: _ctx.vehicles,
          "available-vehicle-compositions": _ctx.availableVehicleCompositions,
          "vehicle-withdrawals": _ctx.vehicleWithdrawal,
          onClose: _cache[2] || (_cache[2] = () => (_ctx.showCreateModal = false)),
          onConfirm: _ctx.fetchVehicleWithdrawal
        }, null, 8, ["causes", "reasons", "depots", "responsibles", "vehicles", "available-vehicle-compositions", "vehicle-withdrawals", "onConfirm"]))
      : _createCommentVNode("", true),
    (_ctx.showEditModal)
      ? (_openBlock(), _createBlock(_component_VehicleWithdrawalEditModal, {
          key: 3,
          causes: _ctx.vehicleWithdrawalCause,
          reasons: _ctx.vehicleWithdrawalReason,
          depots: _ctx.vehicleWithdrawalDepot,
          responsibles: _ctx.vehicleWithdrawalResponsible,
          vehicles: _ctx.vehicles,
          "available-vehicle-compositions": _ctx.availableVehicleCompositions,
          "vehicle-withdrawals": _ctx.vehicleWithdrawal,
          "selected-vehicle-withdrawal": _ctx.selectedVehicleWithdrawal,
          onClose: _cache[3] || (_cache[3] = () => (_ctx.showEditModal = false)),
          onConfirm: _ctx.fetchVehicleWithdrawal
        }, null, 8, ["causes", "reasons", "depots", "responsibles", "vehicles", "available-vehicle-compositions", "vehicle-withdrawals", "selected-vehicle-withdrawal", "onConfirm"]))
      : _createCommentVNode("", true),
    _createVNode(_component_AppFullViewTable, {
      "custom-height": "",
      columns: _ctx.columns,
      rows: _ctx.rows,
      loading: _ctx.loading,
      "action-items": _ctx.actionItems,
      "export-file-name": "Avställningar"
    }, {
      top: _withCtx(() => [
        (_ctx.can('vehicleWithdrawal.create'))
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 0,
              "icon-right": "mdi-plus-circle-outline",
              label: "Lägg till",
              color: "grey-7",
              flat: "",
              stretch: "",
              onClick: _cache[4] || (_cache[4] = () => (_ctx.showCreateModal = true))
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_q_separator, { vertical: "" }),
        _createVNode(_component_q_space)
      ]),
      "body-cell-prognosis": _withCtx((props) => [
        _createElementVNode("div", {
          class: _normalizeClass([
          _ctx.isPrognosisOverdue(props.row.ready, props.value) &&
            _ctx.$style.prognosisWarningBox,
        ])
        }, _toDisplayString(props.value), 3)
      ]),
      _: 1
    }, 8, ["columns", "rows", "loading", "action-items"])
  ], 64))
}