import { VehicleWithdrawalModel } from '@/types/VehicleWithdrawalModel'

export const vehicleWithdrawalModel = (): VehicleWithdrawalModel & {
  prognosis: Date
} => {
  const date = new Date()
  date.setSeconds(0)
  date.setMilliseconds(0)
  return {
    uuid: '',
    vehicle_uuid: null,
    // vehicle_withdrawal_cause_uuid: '',
    vehicle_withdrawal_reason_uuid: '',
    vehicle_withdrawal_responsible_uuid: '',
    vehicle_withdrawal_depot_uuid: '',
    withdrawn: date,
    prognosis: date,
    event_id: null,
    ready: null,
    comment: '',
    wo: '',
  }
}
