
import QForm from 'quasar/src/components/form/QForm.js';
import { defineComponent, PropType, ref } from 'vue'
import VehicleWithdrawalForm from './VehicleWithdrawalForm.vue'
import { vehicleWithdrawalFormProps } from '@/common/vehicleWithdrawalFormProps'
import { VehicleWithdrawalModel } from '@/types/VehicleWithdrawalModel'
import { VehicleWithdrawal } from '@/types/VehicleWithdrawal'
import { updateVehicleWithdrawal } from '@/api/vehicle-withdrawal/updateVehicleWithdrawal'

export const createModel = (
  selectedVehicleWithdrawal: VehicleWithdrawal
): VehicleWithdrawalModel => ({
  uuid: selectedVehicleWithdrawal.uuid,
  vehicle_uuid: selectedVehicleWithdrawal.vehicle_uuid,
  event_id: selectedVehicleWithdrawal.event_id,
  // vehicle_withdrawal_cause_uuid:
  //   selectedVehicleWithdrawal.vehicle_withdrawal_cause_uuid,
  vehicle_withdrawal_reason_uuid:
    selectedVehicleWithdrawal.vehicle_withdrawal_reason_uuid,
  vehicle_withdrawal_responsible_uuid:
    selectedVehicleWithdrawal.vehicle_withdrawal_responsible_uuid,
  vehicle_withdrawal_depot_uuid:
    selectedVehicleWithdrawal.vehicle_withdrawal_depot_uuid,
  withdrawn: new Date(selectedVehicleWithdrawal.withdrawn),
  prognosis: selectedVehicleWithdrawal.prognosis
    ? new Date(selectedVehicleWithdrawal.prognosis)
    : null,
  ready: selectedVehicleWithdrawal.ready
    ? new Date(selectedVehicleWithdrawal.ready)
    : null,
  comment: selectedVehicleWithdrawal.comment,
  wo: selectedVehicleWithdrawal.wo,
})

export default defineComponent({
  name: 'VehicleWithdrawalEditModal',

  components: { VehicleWithdrawalForm },

  emits: ['close', 'confirm'],

  props: {
    ...vehicleWithdrawalFormProps,
    selectedVehicleWithdrawal: {
      type: Object as PropType<VehicleWithdrawal>,
      required: true,
    },
    vehicleWithdrawals: Array as PropType<VehicleWithdrawal[]>,
  },

  setup(props, { emit }) {
    const formRef = ref<QForm | null>(null)
    const pending = ref(false)
    const vehicleFormRef = ref()

    const model = ref<VehicleWithdrawalModel>(
      createModel(props.selectedVehicleWithdrawal)
    )

    const closeModal = () => {
      emit('close')
    }

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return

      const vehicleErrors: VehicleWithdrawal[] =
        vehicleFormRef.value?.errorValidVehicles || []

      if (vehicleErrors.length) return

      const body = Object.assign(
        {},
        {
          ...model.value,
          prognosis: vehicleFormRef.value.showPrognosis
            ? model.value.prognosis
            : null,
        }
      )

      if ('ready' in body) {
        delete body.ready
      }
      pending.value = true
      await updateVehicleWithdrawal(body)
      pending.value = false

      emit('confirm')
      closeModal()
    }

    return {
      closeModal,
      onSubmit,
      formRef,
      model,
      pending,
      vehicleFormRef,
    }
  },
})
